var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm.$options.components['bill-payment-category'] ? [_c('bill-payment-category', {
    attrs: {
      "id": this.$route.params.id
    }
  })] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('span', {
    staticClass: "text-grey1 ml-5"
  }, [_vm._v("Bill Payment > Electricity Bill")]), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "text-center billpayment-tabsec m-4",
    attrs: {
      "data-app": ""
    }
  }, [_c('form', [_c('div', [[_c('model-select', {
    staticClass: "select-style",
    attrs: {
      "options": _vm.getValue,
      "placeholder": "Search State Board"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })]], 2), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "Consumer Number"
    },
    model: {
      value: _vm.value3,
      callback: function callback($$v) {
        _vm.value3 = $$v;
      },
      expression: "value3"
    }
  }), this.selected.value == '204' ? _c('v-text-field', {
    staticClass: "text-left",
    attrs: {
      "label": "Enter mobile number*"
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "buttondv mt-4 ml-5"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary mb-2 mr-2 ml-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.clearData
    }
  }, [_vm._v(" Clear ")]), _c('button', {
    staticClass: "btn btn-primary mb-2 ml-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onClickFetchBill
    }
  }, [_vm._v(" Fetch Bill ")])])], 1)])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }