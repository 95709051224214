<template>
  <b-container>
    <b-row>
      <b-col md="3">
        <template v-if="$options.components['bill-payment-category']">
          <bill-payment-category :id="this.$route.params.id" />
        </template>
      </b-col>
      <b-col md="9">
        <span class="text-grey1 ml-5">Bill Payment > Electricity Bill</span>
        <b-row class="mt-4">
          <b-col md="7">
            <div data-app class="text-center billpayment-tabsec m-4">
              <form>
                <div>
                  <template>
                    <model-select
                      :options="getValue"
                      v-model="selected"
                      class="select-style"
                      placeholder="Search State Board"
                    >
                    </model-select>
                  </template>
                </div>
                <v-text-field
                  class="mt-4"
                  label="Consumer Number"
                  v-model="value3"
                ></v-text-field>
                <v-text-field
                  v-if="this.selected.value == '204'"
                  class="text-left"
                  label="Enter mobile number*"
                  v-model="phone"
                >
                </v-text-field>
                <div class="buttondv mt-4 ml-5">
                  <button
                    type="button"
                    class="btn btn-outline-primary mb-2 mr-2 ml-5"
                    @click="clearData"
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary mb-2 ml-2"
                    @click="onClickFetchBill"
                  >
                    Fetch Bill
                  </button>
                </div>
              </form>
            </div>
          </b-col>
          <!-- <b-col md="5 mb-4">
            <div>
              <h5 class="mb-4">Upcoming Action</h5>
              <template
                v-if="$options.components['bill-payment-action-transaction']"
              >
                <bill-payment-action-transaction />
              </template>
            </div>
            <div>
              <h5 class="mt-4 mb-4">Recent Transaction</h5>
              <template
                v-if="$options.components['bill-payment-action-transaction']"
              >
                <bill-payment-action-transaction />
              </template>
            </div>
          </b-col>
          <b-col md="12" class="mt-4 latest-offer-div">
            <h4 class="mb-4 mt-3">Latest Offers</h4>
            <template v-if="$options.components['bill-payment-latest-offers']">
              <bill-payment-latest-offers />
            </template>
          </b-col>-->
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from "vuex";
import { ModelSelect } from "vue-search-select";

export default {
  name: "electricity-bill",
  components: {
    ModelSelect,
  },
  data() {
    return {
      name: "",
      value1: "",
      value3: "",
      phone: "",
      selectedOperator: "",
      show: false,
      selected: { value: null },
    };
  },
  computed: {
    ...mapState({
      operatorName: (state) => state.billPayment.operatorName,
      billData: (state) => state.billPayment.billData,
    }),
    getValue() {
      return this.operatorName.map((e) => {
        return {
          icon: `https://static.mobikwik.com/appdata/operator_icons/op${e.operatorid}.png`,
          value: e.operatorid,
          text: e.operatorname,
        };
      });
    },
  },
  methods: {
    clearData() {
      (this.value1 = ""), (this.value3 = ""), (this.phone = "");
    },
    async onClickFetchBill() {
      this.value1 = this.selected.value;
      const data = this.operatorName.filter((e) => e.operatorid == this.value1);
      this.name = data[0].operatorname;
      const payload = {
        cn: this.value3.trim(),
        op: this.value1.toString(),
        adParams: { ad1: this.value1 == "204" ? this.phone : "" },
      };
      this.operatorName.map(async (e) => {
        if (e.operatorid == this.value1 && e.viewBillRequired == true) {
          await this.$store
            .dispatch("billPayment/fetchBillApi", payload)
            .then((res) => {
              if (res.data.response.success === true) {
                this.$router.push({
                  name: `checkout-billpayment`,
                  params: {
                    number: this.value3,
                    name: this.name,
                    id: this.value1,
                    phone: this.phone ? this.phone : "",
                    market_id: this.$route.params.id,
                    category: this.$route.params.category,
                  },
                });
              } else {
                setTimeout(() => {
                  this.$swal({
                    text: this.billData.message.text,
                    type: "error",
                  });
                }, 1000);
              }
            });
        }
      });
    },
  },
  async mounted() {
    await this.$store.dispatch("billPayment/fetchcategory");
    this.name = this.$route.params.data.name;
    this.value3 = this.$route.params.data.number;
  },
};
</script>
<style scoped>
.billpayment-tabsec .v-list {
  text-align: left;
}

.buttondv .btn-outline-primary {
  width: 120px;
}
.buttondv .btn-primary {
  width: 120px;
}
.latest-offer-div {
  background-color: #f8f8f8;
}

.option {
  font-weight: 200px;
}
</style>
